import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import moment from 'moment';
import toast from '@/utils/toast';
import { getDueDateForInvoice, isEnableBed } from '@/auth/utils';
import invoiceStoreModule from '../invoiceStoreModule';

export default function useInvoiceList() {
  const STORE_MODULE_NAME = 'invoice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, invoiceStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  const dueDate = getDueDateForInvoice();

  const blankItem = {
    id: 0,
    month: moment(new Date()).format("MM-YYYY"),
    name: '',
    apartment: null,
    room: null,
    bed: null,
    contract: null,
    issueDate: moment(new Date()).toDate(),
    paymentPeriod: null,
    finalDate: null,
    dueDate,
    note: '',
    items: [],
    subTotal: 0,
    discount: 0,
    promotion: 0,
    prepaid: 0,
    tax: 0,
    total: 0,
    typeObject: { title: 'Hóa đơn hàng tháng', id: '1', value: '1' },
    type: '1',
  };

  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };
  const paymentItem = ref({});

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'invoice') && !vm.$can('delete', 'invoice') && !vm.$can('approve', 'invoice') && !vm.$can('pay', 'invoice');
  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
      width: '100px',
    },
    {
      label: 'Thao tác',
      field: 'action',
      tdClass: 'text-center',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: 'Tháng',
      field: 'month',
      width: '100px',
    },
    {
      label: 'Cơ sở',
      field: 'school.name',
    },
    {
      label: 'Lớp',
      field: 'class.name',
    },
    {
      label: 'Học sinh',
      field: 'student.name',
      width: '240px',
    },
    {
      label: 'Học phí',
      field: 'total',
      type: 'number',
    },
    {
      label: 'Đã thanh toán',
      field: 'paid',
      type: 'number',
      width: '150px',
    },
    {
      label: 'Chưa thanh toán',
      field: 'remain',
      type: 'number',
      width: '150px',
    },
    {
      label: 'Hạn TT',
      field: 'dueDate',
    },
    {
      label: 'Duyệt',
      field: 'approve',
    },
    // {
    //   label: 'Xuất hóa đơn VAT',
    //   field: 'isExportVatInvoice',
    // },

  ];
  const rows = ref([]);
  const analytics = ref(null);
  // filter
  const paymentPeriod = ref(null)
  const school = ref(null);
  const classObject = ref(null);
  const student = ref(null);
  const status = ref(null);
  const type = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  const month = ref(null);
  const approveStatus = ref(null);
  const paymentStatus = ref(null);
  const dueDateStatus = ref(null);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {

    },
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call
  const fetchInvoices = () => {
    isLoading.value = false;
    store
      .dispatch('invoice/fetchInvoices', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchAnalytics = () => {
    isLoading.value = false;
    store
      .dispatch('invoice/fetchAnalytics', serverParams.value)
      .then(response => {
        analytics.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchInvoices();
    fetchAnalytics();
  };

  const deleteInvoices = invoices => {
    store
      .dispatch('invoice/deleteInvoices', {
        ids: invoices.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('invoice/importInvoices', formData)
      .then(() => {
        // fetchData();
        toastification.showToastSuccess('Vui lòng đợi chút, hệ thống đang xử lý file của bạn');
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('invoice/exportInvoices', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const downloadImportTemplate = data => {
    let filter = {};
    if (data && data.school && data.school.id) {
      filter = {
        ...filter,
        schoolId: data.school.id,
      };
    }
    if (data && data.class && data.class.id) {
      filter = {
        ...filter,
        classId: data.class.id,
      };
    }
    store
      .dispatch('invoice/generateImportTemplate', { filter })
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportZipImages = () => {
    store
      .dispatch('invoice/exportInvoicesAsZip', serverParams.value)
      .then(() => {
        // window.location.href = response.data;
        toastification.showToastSuccess('Vui lòng đợi chút, hệ thống đang xử lý file của bạn');
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportZipPdf = () => {
    store
      .dispatch('invoice/exportInvoicesAsZipPdf', serverParams.value)
      .then(() => {
        // window.location.href = response.data;
        toastification.showToastSuccess('Vui lòng đợi chút, hệ thống đang xử lý file của bạn');
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = val => {
    item.value = val;
  };

  const setPaymentHidden = invoices => {
    const contractIds = invoices.map(obj => obj.contractId);

    const distinct = new Set(contractIds).size;
    if (distinct === 1) {
      // kiem tra xem tat ca da duoc duyet hay chua
      const countApprove = invoices.filter(obj => obj.approve).length;
      const countRemain = invoices.filter(obj => obj.remain !== 0).length;
      if (countApprove === invoices.length && countRemain === invoices.length) {
        return false;
      }
    }
    return true;
  };

  const setDeleteEnable = invoices => {
    // Chi khi tat ca hoa don chua duyet va chua thanh toan moi xoa duoc, con lai la khong hien thi nut xoa
    if (invoices.length === 0) {
      return false;
    }

    if (invoices.filter(obj => obj.approve === true).length > 0) {
      return false;
    }
    return true;
  };

  const onCreateMultiple = () => {

  };

  const setApproveHidden = invoices => {
    // Tat ca hoa don dang o trang thai chua duyet thi se hien thi nut approve

    const numOfApprove = invoices.filter(obj => obj.approve === false).length;
    return !(invoices.length > 0 && numOfApprove === invoices.length);
  };

  const setDeclineHidden = invoices => {
    // Hiển thị khi tất cả hóa đơn ở trạng thái đã duyệt
    const numOfDecline = invoices.filter(obj => obj.approve === true).length;
    return !(invoices.length > 0 && numOfDecline === invoices.length);
  };

  const approveInvoice = (invoices, approve) => {
    if (invoices && invoices.length > 0) {
      store.dispatch('invoice/approveInvoices', { approve, ids: invoices.map(_obj => _obj.id) }).then(() => {
        fetchInvoices();
      }).catch(error => {
        toastification.showToastError(error);
      });
    }
  };

  //   Watch

  watch(school, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.schoolId = val.id;
    } else {
      delete filter.schoolId;
    }
    updateParams({ filter });
  });
  watch(classObject, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.classId = val.id;
    } else {
      delete filter.classId;
    }
    updateParams({ filter });
  });
  watch(student, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.studentId = val.id;
    } else {
      delete filter.studentId;
    }
    updateParams({ filter });
  });

  watch(status, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.status = val.value;
    } else {
      delete filter.status;
    }
    updateParams({ filter });
  });
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(month, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.month = month.value;
    } else {
      delete filter.month;
    }
    updateParams({ filter });
  });
  watch(approveStatus, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.approve = val.value;
    } else {
      delete filter.approve;
    }
    updateParams({ filter });
  });
  watch(paymentStatus, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.status = val.id;
    } else {
      delete filter.status;
    }
    updateParams({ filter });
  });
  watch(dueDateStatus, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.dueDateStatus = val.id;
    } else {
      delete filter.dueDateStatus;
    }
    updateParams({ filter });
  });
  watch(paymentPeriod, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.paymentPeriodId = val.id;
    } else {
      delete filter.paymentPeriodId;
    }
    updateParams({ filter });
  });

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceApproveStatusVariantAndIcon = approve => {
    if (approve) return { variant: 'success', title: 'Đã duyệt' };
    return { variant: 'danger', title: 'Chưa duyệt' };
  };

  const getRemainDaysVariant = remainDays => {
    if (remainDays > 30) {
      return { variant: "light-primary", text: `Còn ${Math.abs(remainDays)} ngày` };
    }
    if (remainDays > 0) {
      return { variant: "light-warning", text: `Còn ${Math.abs(remainDays)} ngày` };
    }
    if (remainDays === 0) {
      return { variant: "light-warning", text: `Quá hạn hôm nay` };
    }
    return { variant: "light-danger", text: `Quá hạn ${Math.abs(remainDays)} ngày` };
  };

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 3;
    }
    if (isBed) {
      return 0;
    }
    return 4;
  };

  return {
    analytics,
    item,
    paymentItem,
    columns,
    rows,
    school,
    classObject,
    student,
    month,
    status,
    paymentStatus,
    type,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,
    approveStatus,
    dueDateStatus,
    paymentPeriod,
    exportZipPdf,
    importData,
    exportData,
    downloadImportTemplate,
    setPaymentHidden,
    approveInvoice,
    fetchData,
    deleteInvoices,
    onEditItem,
    resetItem,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    resolveInvoiceApproveStatusVariantAndIcon,
    getRemainDaysVariant,
    setApproveHidden,
    setDeclineHidden,
    setDeleteEnable,
    onCreateMultiple,
    resolveColWidthIfDisableBed,
    exportZipImages,
  };
}
